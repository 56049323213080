import React, {useEffect, useRef, useState} from 'react';
import api from "../../middleware/api";
import {
    Row,
    Col,
    Input,
    Modal,
    Spin,
    Select,
    Button,
    Checkbox,
    DatePicker,
    Divider,
    InputNumber,
    Radio,
    Space, Calendar, notification, Tag, List, Comment, Avatar
} from "antd";
import {Form, message} from "antd";
import {
    SendOutlined,
    QuestionCircleOutlined,
    ArrowRightOutlined,
    ArrowLeftOutlined,
    LeftOutlined,
    RightOutlined, PlusOutlined,
} from '@ant-design/icons'
import moment from "moment";
import BookingSlotSelector from "../../components/BookingSlotSelector";
import BookingHistory from "./history";
import CustomerDetail from "../customers/detail";
import CurrencyInput from "../../components/CurrencyInput";
import PriceTable from "./PriceTable";

const BookingDetail = ({id, close, initialValues, copyId}) => {

    const initialPrice = { singlePrice: 0, summary: 0 };

    const [data, setData] = useState({customerId: null});
    const [dataLoading, setDataLoading] = useState(false);
    const [slotsLoading, setSlotsLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [products, setProducts] = useState([]);
    const [latestPersons, setLatestPersons] = useState([]);
    const [resources, setResources] = useState([]);
    const [priceOptions, setPriceOptions] = useState([]);
    const [multiSlotsBooking, setMultiSlotsBooking] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [slots, setSlots] = useState([]);
    const [customSlots, setCustomSlots] = useState([]);
    const [historyVisible, setHistoryVisible] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [bookingConfirmSending, setBookingConfirmSending] = useState(false);
    const [customerDetailId, setCustomerDetailId] = useState(null);
    const [productDuration, setProductDuration] = useState(0);
    const [price, setPrice] = useState( initialPrice );

    const productsRef = useRef([]);


    const [form] = Form.useForm();

    const fetchData = async (id, isCopy = false) => {
        try {
            setDataLoading(true);
            const response = await api.get(`booking/${id}`);
            if(response?.data)
            {
                const data = {...response.data,
                    createdAt: response.data.createdAt ?  moment(response.data.createdAt) : undefined,
                    updatedAt: response.data.updatedAt ? moment(response.data.updatedAt) : undefined,
                    date: response.data.date ? moment(response.data.date, "YYYY-MM-DD") : undefined,
                    bookingType: response.data.bookingType === "online" ? "Onlinebuchung" : "Mitarbeiterbuchung",
                    _bookingType: response.data.bookingType,
                    priceOptions: (response.data.priceOptions || []).reduce((options, option) => {options = {...options, [option.name]: option.persons}; return options; }, {}),
                    ...(isCopy ? {id: undefined, bookingType: undefined, status: undefined, createdAt: undefined, updatedAt: undefined, arrived: undefined, payed: undefined, briefed: undefined, additive: undefined, unhappy: undefined} : {})
                };
                setData(data);
                if (response.data?.customSlots && response.data?.bookingSlots) {
                    setCustomSlots(response.data.bookingSlots.map(slot => {
                        return {
                            startDate: slot,
                            used: response.data.persons,
                            available: 99,
                            max: 99,
                            disabled: false,
                        };
                    }))
                }
            }
        } catch(e) {
            message.error("Laden fehlgeschlagen");
        } finally {
            setDataLoading(false);
        }
    };




    useEffect(() => {
        loadProducts();
        loadCustomers();
    }, []);

    useEffect(() => {
        if(!!id) {
            setData({customerId: null});
            setResources([]);
            setPriceOptions([]);
            setSlots([]);
            setCustomSlots([]);
            setProductDuration(0);
            loadLatestPersons();
            setPrice(initialPrice);
        }
        if (id && id !== 'new') {
            fetchData(id);
        } else {
            if(copyId) {
                fetchData(copyId, true);
                return;
            }
            setData({customerId: null, date: moment(), ...initialValues});
        }
    }, [id, initialValues]);

    const loadInitials = async () => {
        await setProductInfos({reset: false});
        //await loadSlots();
    };

    useEffect(() => {
        form.resetFields();
        if(data.id || initialValues?.productId || (copyId && data.productId)) {
            loadInitials();
        }
    }, [data]);


    const loadSlots = async () => {
        setSlotsLoading(true);
        const formValues = form.getFieldsValue(["productId", "persons", "date"]);

        if(!formValues.productId || !formValues.persons || !formValues.date) {
            if(slots.length > 0) {
                setSlots([]);
            }
            setSlotsLoading(false);
            return;
        }

        const searchParams = new URLSearchParams({
            productId: formValues.productId,
            persons: formValues.persons,
            date: moment(formValues.date).local().format("YYYY-MM-DD"),
            //resourceId: formValues.resourceId,
        });

        if (id !== 'new') {
            searchParams.append('excludeBookingId', id);
        }



        const response = await api.get(`booking/slots?${searchParams.toString()}`);

        if(response.data) {
            const newSlots = response.data?.data || [];
            setSlots(newSlots);
            let selectedSlots = form.getFieldsValue(["bookingSlots"])?.bookingSlots || [];
            if(selectedSlots.length > 0) {
                setPrices(selectedSlots, newSlots);
            }
        }
        setSlotsLoading(false);
    };

    const loadProducts = async () => {
        const response = await api.get(`product`);
        if(response.data) {
            setProducts(response.data);
            productsRef.current = response.data;
        }
    };

    const loadLatestPersons = async () => {
        const response = await api.get(`booking/persons/latest`);
        if(response.data) {
            setLatestPersons(response.data);
        }
    };


    const loadResources = async (reset = true) => {
        //reset && form.setFieldsValue({resourceId: undefined, bookingSlots: []});
        reset && form.setFieldsValue({resourceId: undefined});
        setSlots([]);

        const formValues = form.getFieldsValue(["productId"]);

        const searchParams = new URLSearchParams({
            productId: formValues?.productId || undefined,
        });

        const response = await api.get(`resource?${searchParams.toString()}`);

        if(response.data) {

            setResources(response.data);

            if (response.data.length === 1) {
                //form.setFieldsValue({resourceId: response.data[0].id});
            } else if(!response.data.length) {
                setSlots([]);
            }
            loadSlots();
            loadPriceOptions();
        }

    };

    const loadPriceOptions = async () => {
        const { productId, resourceId } = form.getFieldsValue(["productId", "resourceId"]);

        if(productId && resourceId) {
            const response = await api.get(`pricelist/options/${resourceId}/${productId}`);

            if(response.data) {
                setPriceOptions(response.data || []);
            }
        } else {
            setPriceOptions([]);
        }
    };

    const loadCustomers = async () => {
        const response = await api.get(`customer`);
        if(response.data) {
            setCustomers(response.data);
        }
    };

    const setProductInfos = ({productId = null , productList = null, reset = true}) => {
        const _productId = productId || form.getFieldsValue(['productId']).productId;
        const _products = productList || products;

        const isMultiSlotsBooking = _products.find(product => product.id === _productId)?.multiSlotsBooking || false;
        setMultiSlotsBooking(isMultiSlotsBooking);

        const duration = _products.find(product => product.id === _productId)?.duration || 0;
        setProductDuration(duration);

        loadResources(reset);
    };

    const handleMessageSent = (newData) => {
        setData({...data, messages: newData.messages});
    };

    const handleOk = () => {
        form.submit();
    };

    const cancelBooking = async () => {
        try {
            setCancelLoading(true);
            let noCustomerNotification = false;

            if (id && id !== 'new') {

                const confirm = await confirmCancel();

                if(!confirm) {
                    return;
                }

                if (data._bookingType === 'online') {
                    noCustomerNotification = await askCustomerNotification();
                }

                await api.patch(`booking/${id}`, {status: 'canceled', noCustomerNotification});
                close();
            }

        } catch(e) {
            console.error(e);
            message.error("Speichern fehlgeschlagen");
        }
        finally {
            setCancelLoading(false);
        }
    };

    const resendBookingConfirm = async () => {
        try {
            setBookingConfirmSending(true);

            const response = await api.post(`booking/${id}/sendbookingconfirm`);

            if(response) {
                message.success("Buchungsbestätigung wurde versendet!");
            }
        } catch(e) {
            message.error("Buchungsbestätigung konnte nicht versendet werden");
        }
        finally {
            setBookingConfirmSending(false);
        }
    };

    const confirmCancel = async () => {

        return new Promise((resolve, reject) => {
            Modal.confirm({
                title:
                    'Reservierung wirklich stornieren?',
                icon: <QuestionCircleOutlined />,
                content: "Wollen Sie diese Reservierung wirklich stornieren?",
                okText: 'Ja',
                okType: 'primary',
                cancelText: 'Nein',
                onOk: () => {
                    resolve(true);
                },
                onCancel() { resolve(false); },
            });
        });
    }


    const askCustomerNotification = async (create = false) => {

        return new Promise((resolve, reject) => {
            Modal.confirm({
                title:
                    create ? 'Buchungsbestätigung an Kunden senden?' : 'Kunde über Änderungen benachrichtigen?',
                icon: <QuestionCircleOutlined />,
                content: create ? "Soll eine Buchungsbestätigung per E-Mail an den Kunden versendet werden?" : "Sie haben kundenrelevante Änderungen vorgenommen. Soll der Kunde per E-Mail über Ihre Änderungen informiert werden?",
                okText: 'Ja',
                okType: 'primary',
                cancelText: 'Nein',
                onOk: () => {
                    resolve(false);
                },
                onCancel() { resolve(true); },
            });
        });
    }

    const handleSubmit = async (values) => {
        try {

            if (values.bookingSlots.length === 0) {
                message.error("Bitte wählen sie mindestens einen Slot aus!");
                return;
            }

            if(!values.customSlots && values.bookingSlots.find(selectedSlot => !slots.map(slot => slot.startDate).includes(selectedSlot))) {
                notification.error({message: "Slots nicht verfügbar", description:  "Es sind nicht alle Slots verfügbar. Bitte passen Sie Ihre Buchung an, oder entfernen Sie nicht verfügbare Slots." });
                return;
            }

            let noCustomerNotification = false;
            setSaving(true);
            values = {
                ...values,
                date: values.date ? values.date.format("YYYY-MM-DD") : undefined,
                startDate: values.startDate ? values.startDate.format("YYYY-MM-DD HH:mm") : undefined,
                priceOptions: (Object.keys(values.priceOptions || {}).map((option) => ({ name: option, persons: values.priceOptions[option] })) || []).filter(option => option.persons),
            };

            if (id && id !== 'new') {

                const bookedStartTimesInUnix = data?.bookingSlots.map((slot) => moment(`${slot}`, 'YYYY-MM-DD HH:mm').toDate().getTime());
                const updatedStartTimesInUnix = values?.bookingSlots.map((slot) => moment(`${slot}`, 'YYYY-MM-DD HH:mm').toDate().getTime());
                const bookingSlotsEqual = bookedStartTimesInUnix.every((time) => updatedStartTimesInUnix.includes(time)) && updatedStartTimesInUnix.every((time) => bookedStartTimesInUnix.includes(time));

                if ((data?.customerId || data?.guest?.mail) &&
                    ((values?.persons && values.persons !== data.persons) ||
                    (values?.productId && values.productId !== data.productId) ||
                    (values?.resourceId && values.resourceId !== data.resourceId) ||
                    (values?.specialPrice !== data.specialPrice) ||
                    (values?.bookingSlots && !bookingSlotsEqual))
                ) {
                    noCustomerNotification = await askCustomerNotification();
                }

                await api.patch(`booking/${id}`, {...values, noCustomerNotification });
            } else {

                if (values?.customerId || values?.guest?.mail) {
                    noCustomerNotification = await askCustomerNotification(true);
                }

                await api.put(`booking`, { ...values, noCustomerNotification });
            }

            message.success("Gespeichert");
            close();
        } catch(e) {
            console.log(e);


            switch(e.response.data?.errorId) {
                case "SLOT_UNAVAILABLE":
                    notification.error({message: "Slots nicht verfügbar", description:  "Es sind nicht alle Slots verfügbar. Bitte passen Sie Ihre Buchung an, oder entfernen Sie nicht verfügbare Slots." });
                    break;
                case "PRODUCT_UNAVAILABLE":
                    notification.error({message: "Produkt nicht aktiv", description:  "Das ausgewählte Produkt ist nicht aktiv. Bitte wählen Sie ein anderes Produkt." });
                    break;
                default:
                    message.error("Speichern fehlgeschlagen");
            }
        } finally {
            setSaving(false);
        }
    };

    const changeSlotDate = (date) => {
        let selectedSlots = form.getFieldsValue(['bookingSlots'])?.bookingSlots || [];
        const dateStr = date.format('YYYY-MM-DD');

        selectedSlots = selectedSlots.map(slot => `${dateStr} ${moment(slot, 'YYYY-MM-DD HH:mm').format("HH:mm")}`);

        form.setFieldsValue({bookingSlots: selectedSlots});

        // update custom slots
        const newCustomSlots = customSlots.map(slot => {
         return {
             ...slot,
             startDate: `${dateStr} ${moment(slot.startDate, 'YYYY-MM-DD HH:mm').format("HH:mm")}`,
             endDate: `${dateStr} ${moment(slot.endDate, 'YYYY-MM-DD HH:mm').format("HH:mm")}`,
         }
        });
        setCustomSlots(newCustomSlots);

    };

    /*const setEndDate = () => {

        const startDate = form.getFieldsValue(['startDate'])?.startDate;
        const productId = form.getFieldsValue(['productId'])?.productId;
        const productDuration = products.find(prod => prod.id === productId)?.duration;
        if (!startDate || !productId || !productDuration) {
            return;
        }

        form.setFieldsValue({endDate: moment(startDate).add(moment.duration(productDuration))})
    };*/

    const Status = {
        "checked": "geprüft",
        "pending": "ausstehend",
        "confirmed": "bestätigt",
        "canceled": "storniert",
    };


    const selectLastPerson = (e) => {

        // if number is longer, we assume it's an customer id, so skip thos
        if (e === null || e.toString().length > 2) {
            return;
        }

        const person = latestPersons[e];

        if(person && person?.customer) {
            form.setFieldsValue({customerId: person.customer.id});
        } else if(person && person?.guest) {
            form.setFieldsValue({
                customerId: null,
                guest: person.guest,
            });
        }
    };



    const calculatePriceOption = (actPriceInfo, persons, slotStartDate, priceOptionValues, activePriceOptionKeys) => {

        const result = {
            sumPersons: 0,
            sumPrice: 0,
            options: []
        }
            const actPriceOptions = activePriceOptionKeys.map(optionName => {
                const personsWithThisOption = Math.min((priceOptionValues?.[optionName] || 1), persons);

                // price options with current name
                let priceOptionPersonsToUse = (actPriceInfo.priceOption || []).filter(infoOption => infoOption.name === optionName) || [];

                // filter to price options with less (or equal) persons than currently selected
                priceOptionPersonsToUse = priceOptionPersonsToUse.map((priceOption) => priceOption.persons).filter((priceOptionPersons) => priceOptionPersons <= personsWithThisOption);
                // filter out max persons - we want to use the cheapest option so we need to find the one with max persons
                priceOptionPersonsToUse = Math.max(...priceOptionPersonsToUse, 1);

                // return price option to use
                return (actPriceInfo.priceOption || []).find(infoOption => infoOption.name === optionName && infoOption.persons === priceOptionPersonsToUse);
            }).filter(option => option);

            actPriceOptions.forEach(priceOption => {


                const personsWithThisOption = Math.min((priceOptionValues?.[priceOption.name] || 1), persons);
                const actSinglePrice = parseFloat(priceOption.pricePerPerson || 0);


                result.sumPersons += personsWithThisOption;

                result.options.push(
                    {
                        position: `${priceOption.name} - ${moment(slotStartDate, 'YYYY-MM-DD HH:mm').format('DD.MM.YY - HH:mm')} Uhr`,
                        persons: personsWithThisOption,
                        singlePrice: `${actSinglePrice.toFixed(2).replace('.', ',')} €`,
                        summary: `${parseFloat(actSinglePrice * personsWithThisOption).toFixed(2).replace('.', ',')} €`,
                    }
                )
                result.sumPrice += parseFloat(actSinglePrice * personsWithThisOption);

            });

            return result;
    }

    const setPrices = (selectedStartDates, customSlots) => {

        const _slots = customSlots || slots;

        const productId = form.getFieldsValue(['productId']).productId || initialValues.productId;


        // TODO:  || initialValues.priceOption;
        let priceOptionValues = form.getFieldsValue(['priceOptions']).priceOptions || {};
        const activePriceOptionKeys = Object.keys(priceOptionValues).filter(key => priceOptionValues[key] > 0);


        const productInfo = (productsRef.current || []).find(product => product.id === productId) || {};

        if(!Object.keys(productInfo).length) {
            return;
        }

        let discountsToSet = [];
        let priceSurcharge = 0;

        //let newPrices = { singlePrice: 0, summary: 0 };

        let newPrices = { priceList: [], singlePrice: 0, groupPrice: 0, summary: 0 };

        let persons = form.getFieldsValue(["persons"])?.persons || 1;


        let priceInfo = _slots.find(slot => selectedStartDates.includes(slot.startDate))?.priceList || {};
        //const priceList = priceInfo?.price || [];
        //console.log("priceList priceList ", priceList);
        if (!Object.keys(priceInfo).length) {
            const selectedStartDatesMoment = selectedStartDates.map((startDateString) => moment(startDateString, 'YYYY-MM-DD HH:mm'));

            priceInfo =
                _slots.filter((slot) =>
                    selectedStartDatesMoment
                        .find((startDate) =>
                            startDate.isAfter(
                                moment(slot.startDate, 'YYYY-MM-DD HH:mm')
                            )
                        )
                );
            priceInfo = priceInfo?.[priceInfo.length - 1]?.priceList || {}
        }


        // calculate price options

        if (priceInfo.type === 'discount') {


            const priceList = priceInfo?.priceSlot || [];
            if (priceList.length > 1) {
                discountsToSet = priceList.filter(price => price.gameNumber !== 1);

                /*discountsToSet = discountsToSet.map(discount => {
                    if (persons >= (priceInfo?.groupDiscountMinPersons || 0)) {
                        discount.pricePerPerson = (discount.pricePerPerson * ((100 - (priceInfo?.groupDiscount || 0)) / 100)).toFixed(2);
                    }
                    return discount;
                });*/
            }

            selectedStartDates.forEach((slotStartDate, i) => {

                const actPriceInfo =
                    _slots.find((slot) => slotStartDate === slot.startDate)
                        ?.priceList || priceInfo;

                const gameNumber = i + 1;
                let actPrice = 0;

                const actPriceOptions = calculatePriceOption(actPriceInfo, persons, slotStartDate, priceOptionValues, activePriceOptionKeys);
                const personsWithoutOption = persons - actPriceOptions.sumPersons;

                const directDiscountPrice = discountsToSet.find(discount => discount.gameNumber === gameNumber)?.pricePerPerson || null;
                if (directDiscountPrice !== null) {
                    actPrice = directDiscountPrice;
                } else {
                    const recurringDiscountPrice = discountsToSet.find(discount => gameNumber % discount.gameNumber === 0)?.pricePerPerson || null;
                    if (recurringDiscountPrice !== null) {
                        actPrice = recurringDiscountPrice;
                    } else {
                        actPrice = (_slots.find(slot => slotStartDate === slot.startDate)?.priceList?.priceSlot || []).find(price => price.gameNumber === 1)?.pricePerPerson || 0;
                    }
                }

                const actSinglePrice = parseFloat(actPrice || 0);

                newPrices.priceList.push({
                    position: <><b>{productInfo.name}</b> - {moment(slotStartDate, 'YYYY-MM-DD HH:mm').format('DD.MM.YY - HH:mm')} Uhr</>,
                    persons: personsWithoutOption > 0 ? personsWithoutOption : " ",
                    singlePrice: personsWithoutOption > 0 ? `${actSinglePrice.toFixed(2).replace('.', ',')} €` : " ",
                    summary: personsWithoutOption > 0 ? `${parseFloat(actSinglePrice * personsWithoutOption).toFixed(2).replace('.', ',')} €` : " ",
                });

                if(priceInfo.minPrice && parseFloat(String(priceInfo.minPrice)) > (parseFloat(actSinglePrice * personsWithoutOption) + actPriceOptions.sumPrice)) {

                    priceSurcharge += (parseFloat(String(priceInfo.minPrice)) - (parseFloat(actSinglePrice * personsWithoutOption) + actPriceOptions.sumPrice));

                }

                // add price options to price list
                actPriceOptions.options.forEach(actPriceOption => {
                    newPrices.priceList.push(actPriceOption);
                });


                // add price options price to summary
                newPrices.summary += actPriceOptions.sumPrice;

                // add single price
                newPrices.singlePrice += actSinglePrice;
                // Add normal price to summary
                newPrices.summary += parseFloat(actSinglePrice * personsWithoutOption);
            });

            let groupDiscountPersonsToUse = (priceInfo?.groupDiscount || []).map((groupDiscount) => groupDiscount.persons).filter((discountPersons) => discountPersons <= persons);

            groupDiscountPersonsToUse = Math.max(...groupDiscountPersonsToUse, 0);

            const groupDiscountToUse = (priceInfo?.groupDiscount || []).find((groupDiscount) => groupDiscount.persons === groupDiscountPersonsToUse);



            if (groupDiscountToUse && groupDiscountToUse?.discount > 0 && ( !priceInfo.minPrice || (parseFloat(String(priceInfo.minPrice)) < newPrices.summary) )) {

                newPrices.priceList.push({
                    position: <b>Gruppenrabatt {groupDiscountToUse?.discount} %</b>,
                    persons: "",
                    singlePrice: ` `,
                    discount: true,
                    summary: `- ${((newPrices.summary * (((groupDiscountToUse?.discount || 0)) / 100))).toFixed(2).replace('.', ',')} €`,
                });

                // calculate new single price
                newPrices.singlePrice = (newPrices.singlePrice * ((100 - (groupDiscountToUse?.discount || 0)) / 100)).toFixed(2);

                // subtract group discount from summary
                newPrices.summary = (newPrices.summary * ((100 - (groupDiscountToUse?.discount || 0)) / 100));
            }

        } else if (priceInfo.type === 'tier') {

            selectedStartDates.forEach((slotStartDate) => {
                const actPriceInfo =
                    _slots.find((slot) => slotStartDate === slot.startDate)
                        ?.priceList || priceInfo;


                const actPriceOptions = calculatePriceOption(actPriceInfo, persons, slotStartDate, priceOptionValues, activePriceOptionKeys);

                const personsWithoutOption = persons - actPriceOptions.sumPersons;

                let priceTierPersonsToUse = (actPriceInfo?.priceTier || []).map((priceTier) => priceTier.persons).filter((pricePersons) => pricePersons <= persons);
                priceTierPersonsToUse = Math.max(...priceTierPersonsToUse, 0);

                const priceTierToUse = (actPriceInfo?.priceTier || []).find((priceTier) => priceTier.persons === priceTierPersonsToUse) || {};

                const actSinglePrice = parseFloat(priceTierToUse?.pricePerPerson || "0");

                newPrices.priceList.push({
                    position: <><b>{productInfo.name}</b> - {moment(slotStartDate, 'YYYY-MM-DD HH:mm').format('DD.MM.YY - HH:mm')} Uhr</>,
                    persons: personsWithoutOption > 0 ? personsWithoutOption : " ",
                    singlePrice: personsWithoutOption > 0 ? `${actSinglePrice.toFixed(2).replace('.', ',')} €` : ` `,
                    summary: personsWithoutOption > 0 ? `${parseFloat(actSinglePrice * personsWithoutOption).toFixed(2).replace('.', ',')} €` : ` `,
                });

                if(priceInfo.minPrice && parseFloat(String(priceInfo.minPrice)) > (parseFloat(actSinglePrice * personsWithoutOption) + actPriceOptions.sumPrice)) {

                    priceSurcharge += (parseFloat(String(priceInfo.minPrice)) - (parseFloat(actSinglePrice * personsWithoutOption) + actPriceOptions.sumPrice));

                }

                // add price options to price list
                actPriceOptions.options.forEach(actPriceOption => {
                    newPrices.priceList.push(actPriceOption);
                });

                // add price options price to summary
                newPrices.summary += actPriceOptions.sumPrice;

                // add single price
                newPrices.singlePrice = actSinglePrice;
                // Add normal price to summary
                newPrices.summary += parseFloat(actSinglePrice * personsWithoutOption);
            });

            // round single price
            newPrices.singlePrice = parseFloat(newPrices.singlePrice).toFixed(2);

        } else if (priceInfo.type === 'group') {

            // HINT: currently we have no price options for group prices!!

            selectedStartDates.forEach((slotStartDate) => {
                const actPriceInfo =
                    _slots.find((slot) => slotStartDate === slot.startDate)
                        ?.priceList || priceInfo;

                let priceGroupPersonsToUse = (actPriceInfo?.priceGroup || []).map((priceGroup) => priceGroup.persons).filter((pricePersons) => pricePersons <= persons);
                priceGroupPersonsToUse = Math.max(...priceGroupPersonsToUse, 0);

                const priceGroupToUse = (actPriceInfo?.priceGroup || []).find((priceGroup) => priceGroup.persons === priceGroupPersonsToUse) || {};

                const actGroupPrice = parseFloat(priceGroupToUse?.pricePerGroup || "0");

                newPrices.priceList.push({
                    position: `${productInfo.name} - ${moment(slotStartDate, 'YYYY-MM-DD HH:mm').format('DD.MM.YY - HH:mm')} Uhr`,
                    persons: persons,
                    singlePrice: `${actGroupPrice.toFixed(2).replace('.', ',')} €`,
                    summary: `${actGroupPrice.toFixed(2).replace('.', ',')} €`,
                });

                newPrices.groupPrice += actGroupPrice;
                //newPrices.singlePrice += actGroupPrice;
            });

            // round single price
            newPrices.singlePrice = parseFloat(newPrices.singlePrice).toFixed(2);
        }


        // Mindestpreis Aufschlag oder Gruppenpreis

        if (newPrices.groupPrice) {

            newPrices.summary = newPrices.groupPrice;

        } else {
            //newPrices.summary =  parseFloat(newPrices.singlePrice * persons).toFixed(2);
            //newPrices.summary += parseFloat(newPrices.singlePrice * persons);

            if(priceSurcharge) {
                newPrices.priceList.push({
                    position: <b>Mindestpreis Aufschlag</b>,
                    persons: "",
                    singlePrice: ` `,
                    discount: false,
                    summary: `${parseFloat(priceSurcharge).toFixed(2).replace('.', ',')} €`,
                });

                newPrices.summary += parseFloat(priceSurcharge);
            }
        }





        /*if(priceInfo.minPrice && parseFloat(String(priceInfo.minPrice)) > newPrices.summary) {

            newPrices.priceList.push({
                position: `Mindestpreis Aufschlag`,
                persons: "",
                singlePrice: ` `,
                discount: false,
                summary: `${(parseFloat(String(priceInfo.minPrice)) - newPrices.summary).toFixed(2).replace('.', ',')} €`,
            });

            newPrices.summary = parseFloat(String(priceInfo.minPrice));

        }*/

        newPrices.summary = newPrices.summary.toFixed(2);

        const sonderPreis = form.getFieldsValue(['specialPrice']).specialPrice;
        if (sonderPreis) {
            newPrices.summary = sonderPreis;
        }

        setPrice(newPrices);

    };

    const handleCloseCustomerDetail = (resultValues) => {
        if (customerDetailId === 'new') {
            loadCustomers();
            if(resultValues?.id) {
                form.setFieldsValue({customerId: resultValues.id});
            }
        }
        setCustomerDetailId(null);
    }



    return (
        <Modal
            maskClosable={false}
            destroyOnClose={true}
            visible={!!id}
            title={id !== 'new' ? "Reservierung bearbeiten" : "Reservierung anlegen"}
            onCancel={close}
            onOk={handleOk}
            width={window.innerWidth < 1024 ? "100%" : "85%"}
            footer={[
                <>
                    {id !== 'new' ?
                        <>
                            <Button key="history" type="default" onClick={() => setHistoryVisible(true)}>
                                Verlauf
                            </Button>
                            <Button key="resendConfirm" type="default" onClick={() => resendBookingConfirm()} loading={bookingConfirmSending}>
                                Buchungsbest. senden
                            </Button>
                        </>
                        : null}
                </>,
                <>
                    {(data?.status || "canceled") !== 'canceled' && <Button type={"danger"} htmlType={"button"} onClick={cancelBooking} disabled={cancelLoading} loading={cancelLoading} >Stornieren</Button>}
                </>,
                <Button key="cancel" type="default" onClick={close}>
                    Abbrechen
                </Button>,
                <Button key="ok" type="primary" onClick={handleOk}>
                    Speichern
                </Button>,

            ]}
        >
            <Spin spinning={dataLoading || saving}>
                <CustomerDetail
                    id={customerDetailId}
                    close={handleCloseCustomerDetail}
                />
                <BookingHistory id={id} visible={historyVisible} close={() => setHistoryVisible(false)} />
                <Form
                    form={form}
                    onFinish={handleSubmit}
                    initialValues={data}
                    layout={"vertical"}
                >
                    <Form.Item name="resourceId" hidden={true}>
                        <Input type="hidden" />
                    </Form.Item>

                    <Row
                        gutter={50}
                    >
                        <Col md={24} lg={12} style={{borderRight: '1px solid rgba(255, 255, 255, 0.12)'}}>
                            <Row gutter={16} >
                                <Col span={24}>
                                    <Divider orientation="left">Reservierung</Divider>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={"Produkt"}
                                        name={"productId"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            dropdownMatchSelectWidth={false}
                                            onChange={(value) => {
                                                setProductInfos({productId: value});
                                            }}
                                        >
                                            {products.map(product => <Select.Option key={product.id} value={product.id}>
                                                {product.name} &nbsp;{!product.isActive && <Tag color="orange">inaktiv</Tag>}
                                                {product.difficulty ? <Tag color={"green"}>{product.difficulty}</Tag> : null}
                                            </Select.Option>
                                            )}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Anzahl der Personen"}
                                        name={"persons"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <InputNumber style={{width: '100%'}} min={1}  onChange={() => loadSlots()} />
                                    </Form.Item>
                                </Col>


                                {/*<Col span={24}>
                                    <Form.Item
                                        label={"Ressource / Raum"}
                                        name={"resourceId"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Radio.Group  onChange={() => {loadSlots(); loadPriceOptions(); /*form.setFieldsValue({bookingSlots: []});* / }}>
                                            <Space direction="vertical">
                                                {resources.map(resource => <Radio key={resource.id} value={resource.id}>{resource.name}</Radio>)}
                                            </Space>
                                        </Radio.Group>
                                        {/*<Select
                                            showSearch
                                            optionFilterProp="children"
                                            onChange={() => loadSlots()}
                                        >
                                            {resources.map(resource => <Select.Option key={resource.id} value={resource.id}>{resource.name}</Select.Option>)}
                                        </Select>* /}
                                    </Form.Item>
                                </Col>*/}

                                <Col span={24}>
                                    <Row gutter={15}>
                                        <Col span={12}>
                                            <Form.Item
                                                label={"Jüngster Teilnehmer"}
                                                name={"minAge"}
                                            >
                                                {/*<Select
                                                    placeholder={"Alter auswählen (jüngster)"}
                                                    style={{width: '100%'}}
                                                >
                                                    <Select.Option value={7}>&lt; 8</Select.Option>
                                                    <Select.Option value={8}>8-10</Select.Option>
                                                    <Select.Option value={10}>10-12</Select.Option>
                                                    <Select.Option value={12}>12-14</Select.Option>
                                                    <Select.Option value={14}>14-18</Select.Option>
                                                    <Select.Option value={18}>18+</Select.Option>
                                                </Select>*/}
                                                <InputNumber placeholder={"Alter jüngster Teilnehmer"}
                                                             style={{width: '100%'}}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                label={"Ältester Teilnehmer"}
                                                name={"maxAge"}
                                            >
                                                {/*<Select
                                                    placeholder={"Alter auswählen (ältester)"}
                                                    style={{width: '100%'}}
                                                >
                                                    <Select.Option value={7}>&lt; 8</Select.Option>
                                                    <Select.Option value={8}>8-10</Select.Option>
                                                    <Select.Option value={10}>10-12</Select.Option>
                                                    <Select.Option value={12}>12-14</Select.Option>
                                                    <Select.Option value={14}>14-18</Select.Option>
                                                    <Select.Option value={18}>18+</Select.Option>
                                                </Select>*/}
                                                <InputNumber placeholder={"Alter ältester Teilnehmer"}
                                                             style={{width: '100%'}}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Col>


                                <Col span={24}>
                                    <Row gutter={15}>

                                        {priceOptions.map(option => (<Col span={12}>
                                                <Form.Item
                                                    label={option}
                                                    name={["priceOptions", option]}
                                                >
                                                    <InputNumber placeholder={"Anzahl"}
                                                                 style={{width: '100%'}}
                                                                 min={0}
                                                                 onChange={() => loadSlots()}
                                                    />
                                                </Form.Item>
                                            </Col>))}

                                    </Row>

                                </Col>


                                <Col span={24}>
                                    <Divider orientation="left">Datum und Uhrzeit</Divider>
                                </Col>

                                <Col span={24}>
                                    <Form.Item name={"customSlots"}>
                                        <Radio.Group>
                                            <Radio.Button value={false}>
                                                Slots
                                            </Radio.Button>
                                            <Radio.Button value={true}>
                                                Benutzerdefiniert
                                            </Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>

                                {/*} <Col span={24}>
                                            <Divider orientation="left">Gebuchte Slots</Divider>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name={"bookingSlots"}
                                            >
                                                <BookedSlots/>
                                            </Form.Item>
                                        </Col>
                        */}


                                <Col span={24}>
                                    <Row gutter={15}>
                                        <Col lg={24} xl={12}>
                                            <Form.Item
                                                label={"Datum"}
                                                name={"date"}
                                            >
                                                <Calendar
                                                    fullscreen={false}
                                                    style={{width: '100%'}}
                                                    format={"DD.MM.YYYY"}
                                                    onChange={(date) => {
                                                        loadSlots();
                                                        changeSlotDate(date);
                                                        /*form.setFieldsValue({bookingSlots: []});*/
                                                    }}
                                                    headerRender={({ value, type, onChange, onTypeChange }) => {
                                                        const current = value.clone();
                                                        const localeData = value.localeData();
                                                        const months = [];
                                                        for (let i = 0; i < 12; i++) {
                                                            current.month(i);
                                                            months.push(localeData.months(current));
                                                        }
                                                        const month = value.month();
                                                        const year = value.year();

                                                        const changeMonth = (step) => {
                                                            const newValue = value.clone();
                                                            newValue.add(step, 'months');
                                                            onChange(newValue);
                                                        };

                                                        return (
                                                            <div style={{ padding: 8 }}>
                                                                <Row gutter={8}>
                                                                    <Col span={4}>
                                                                        <div className={'calendar-header-arrow'} onClick={() => changeMonth(-1)}>
                                                                            <LeftOutlined />
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={16}>
                                                                        <div style={{textAlign: 'center', fontWeight: 'bold'}}>
                                                                            {months[String(month)]} {year}
                                                                        </div>
                                                                    </Col>
                                                                    <Col span={4} style={{textAlign: 'right'}}>
                                                                        <div className={'calendar-header-arrow'} onClick={() => changeMonth(1)}>
                                                                            <RightOutlined />
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        );
                                                    }}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Form.Item shouldUpdate noStyle>
                                            {() => form.getFieldsValue(['customSlots']).customSlots ?
                                                    <Col lg={24} xl={12}>
                                                        <Form.Item
                                                            label={"Ressource / Raum"}
                                                            name={"resourceId"}
                                                            required={true}
                                                            rules={[{required: true}]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                optionFilterProp="children"
                                                                onChange={() => loadSlots()}
                                                            >
                                                                {resources.map(resource => <Select.Option key={resource.id} value={resource.id}>{resource.name}</Select.Option>)}
                                                            </Select>
                                                        </Form.Item>

                                                        <Form.Item
                                                            label={"Slots"}
                                                            name={"bookingSlots"}
                                                        >
                                                            <BookingSlotSelector
                                                                slots={customSlots}
                                                                multiSelect={multiSlotsBooking}
                                                                persons={form.getFieldsValue(['persons'])?.persons || 0}
                                                                slotsLoading={false}
                                                                onCreate={(newSlot) => {
                                                                    setCustomSlots([...customSlots, newSlot]);
                                                                }}
                                                                getDate={() => form.getFieldsValue(['date']).date}
                                                                duration={productDuration}
                                                                onChange={(slotStartDates) => setPrices(slotStartDates)}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                :
                                                    <Col lg={24} xl={12}>
                                                        <Form.Item
                                                        label={"Slots"}
                                                        name={"bookingSlots"}
                                                        >
                                                        <BookingSlotSelector
                                                            slots={slots}
                                                            multiSelect={multiSlotsBooking}
                                                            persons={form.getFieldsValue(['persons'])?.persons || 0}
                                                            resourceId={form.getFieldsValue(['resourceId'])?.resourceId || ""}
                                                            slotsLoading={slotsLoading}
                                                            onChange={(slotStartDates, newResourceId) => {setPrices(slotStartDates); form.setFieldsValue({resourceId: newResourceId})}}
                                                        />
                                                        </Form.Item>
                                                    </Col>
                                            }
                                        </Form.Item>

                                        {/*<Col span={12}>
                                            <Row>
                                                <Col span={24}>
                                                    <Divider orientation="left">Verfügbare Slots</Divider>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label={"Datum"}
                                                        name={"date"}
                                                    >
                                                        <DatePicker
                                                            style={{width: '100%'}}
                                                            format={"DD.MM.YYYY"}
                                                            onChange={() => {
                                                                loadSlots();
                                                                form.setFieldsValue({bookingSlots: []});
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <Form.Item
                                                        label={"Slots"}
                                                        name={"bookingSlots"}
                                                    >
                                                        <BookingSlotSelector slots={slots} multiSelect={multiSlotsBooking} persons={form.getFieldsValue(['persons'])?.persons || 0} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <Row>
                                                <Col span={24}>
                                                    <Divider orientation="left">Gebuchte Slots</Divider>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        name={"bookingSlots"}
                                                    >
                                                        <BookedSlots/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>*/}
                                    </Row>
                                </Col>



                                {/*<Col span={12}>
                                    <Form.Item
                                        label={"Beginn"}
                                        name={"startDate"}
                                    >
                                        <DatePicker
                                            minuteStep={15}
                                            showTime
                                            style={{width: '100%'}}
                                            format={"DD.MM.YYYY HH:mm"}
                                            onChange={() => setEndDate()}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Ende"}
                                        name={"endDate"}
                                    >
                                        <DatePicker
                                            minuteStep={15}
                                            showTime
                                            style={{width: '100%'}}
                                            format={"DD.MM.YYYY HH:mm"}
                                        />
                                    </Form.Item>
                                </Col>*/}



                                <Col span={24}>
                                    <Divider orientation="left">Informationen</Divider>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Buchungstyp"}
                                        name={"bookingType"}
                                    >
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}></Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Erstellt"}
                                        name={"createdAt"}
                                    >
                                        <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label={"Zuletzt bearbeitet"}
                                        name={"updatedAt"}
                                    >
                                        <DatePicker showTime style={{width: '100%'}} disabled format={"DD.MM.YYYY HH:mm"} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={24} lg={12}>
                            <Row gutter={16} >
                                <Col span={24}>
                                    <Divider orientation="left">Notizen</Divider>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={"interne Bemerkungen"}
                                        name={"notes"}
                                        required={false}
                                    >
                                        <Input.TextArea autoSize={{ minRows: 2, maxRows: 4 }} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <label>Bemerkung / Nachricht an den Kunden</label>
                                    {(data?.messages || []).length > 0 && <CommentList comments={data.messages} />}
                                </Col>
                                <Col span={24}>
                                    <Comment
                                        content={
                                            <AddMessage bookingId={id} onSuccess={handleMessageSent} disabled={!(data.customerId || data?.guest?.mail)} />
                                        }
                                    />
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label={"Sonderpreis"}
                                        name={"specialPrice"}
                                        required={false}
                                        extra={"Feld leer lassen um die automatische Preiskalkulation zu verwenden"}
                                    >
                                        <CurrencyInput placeholder={"Kein Sonderpreis"} onBlur={() => setPrices(form.getFieldsValue(["bookingSlots"])?.bookingSlots || [])} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <PriceTable price={price} specialPrice={form.getFieldsValue(['specialPrice']).specialPrice} />
                                </Col>

                                <Col span={24}>
                                    <Divider orientation="left">Angaben zur Person</Divider>
                                </Col>

                                {/*<Col span={24}>
                                    <Form.Item
                                        label={"Zuletzt verwendet"}
                                        name={""}
                                    >
                                        <Select onChange={selectLastPerson}>
                                            {latestPersons.map((person, personIndex) => {
                                                const personInfo = person?.customer ? person.customer : person.guest;
                                                return (<Select.Option key={`latest-person-${personIndex}`} value={personIndex}>{personInfo.company ? personInfo.company + " - " : ""} {personInfo.lastName} {personInfo.firstName} - {personInfo.address} {personInfo.zipCode} {personInfo.city} </Select.Option>);
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>*/}
                                <Col span={24}>
                                    <Row gutter={16} >
                                        <Col flex={1}>
                                            <Form.Item
                                                label={"Kunde"}
                                                name={"customerId"}
                                            >
                                                <Select
                                                    showSearch
                                                    optionFilterProp="children"
                                                    onChange={selectLastPerson}
                                                >
                                                    <Select.OptGroup label="Zuletzt verwendet">
                                                        {latestPersons.map((person, personIndex) => {
                                                            const personInfo = person?.customer ? person.customer : person.guest;
                                                            return (<Select.Option key={`latest-person-${personIndex}`} value={personIndex}>{personInfo.company ? personInfo.company + " - " : ""} {personInfo.lastName} {personInfo.firstName} - {personInfo.address} {personInfo.zipCode} {personInfo.city} </Select.Option>);
                                                        })}
                                                    </Select.OptGroup>
                                                    <Select.OptGroup label="Neue Auswahl">
                                                        <Select.Option key={"guest"} value={null}>-- Gast --</Select.Option>
                                                        {customers.map(customer => <Select.Option key={customer.id} value={customer.id}>{customer.company ? customer.company + " - " : ""} {customer.lastName} {customer.firstName} - {customer.address} {customer.zipCode} {customer.city} </Select.Option>)}
                                                    </Select.OptGroup>
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col><Form.Item label={" "}><Button type="default" htmlType="button" icon={<PlusOutlined />} onClick={() => setCustomerDetailId("new")}>Neu anlegen</Button></Form.Item></Col>

                                    </Row>
                                </Col>

                                <Form.Item shouldUpdate noStyle>
                                    {() => form.getFieldsValue(['customerId']).customerId === null ? <>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={"Anrede"}
                                                    name={["guest", "title"]}
                                                >
                                                    <Select>
                                                        <Select.Option value={"mr"}>Herr</Select.Option>
                                                        <Select.Option value={"mrs"}>Frau</Select.Option>
                                                        <Select.Option value={"company"}>Firma</Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item
                                                    label={"Firma"}
                                                    name={["guest", "company"]}
                                                    required={form.getFieldsValue(['guest'])?.guest?.title === "company"}
                                                    rules={[{required: form.getFieldsValue(['guest'])?.guest?.title === "company"}]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item
                                                    label={"Vorname"}
                                                    name={["guest", "firstName"]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={"Nachname"}
                                                    name={["guest", "lastName"]}
                                                    required={true}
                                                    rules={[{required: true}]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item
                                                    label={"Straße, Hausnummer"}
                                                    name={["guest", "address"]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={"PLZ"}
                                                    name={["guest", "zipCode"]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={"Ort"}
                                                    name={["guest", "city"]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    label={"E-Mail Adresse"}
                                                    name={["guest", "mail"]}
                                                    rules={[ {type: 'email'}]}
                                                >
                                                    <Input type={"email"} />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item
                                                    label={"Telefon"}
                                                    name={["guest", "phone"]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </>
                                        :
                                        <Col span={24}>
                                            <Button htmlType="button" type="default" block onClick={() => setCustomerDetailId(form.getFieldsValue(['customerId']).customerId)} >Kunde anzeigen</Button>
                                        </Col>
                                        }
                                </Form.Item>


                                <Col span={24}>
                                    <Divider orientation="left">Aufgaben</Divider>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name={"arrived"}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox >Angekommen</Checkbox>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name={"payed"}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox >Bezahlt</Checkbox>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name={"briefed"}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox >Eingewiesen</Checkbox>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name={"additive"}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox >Zusatz</Checkbox>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name={"unhappy"}
                                        valuePropName={"checked"}
                                    >
                                        <Checkbox >Kunde unzufrieden</Checkbox>
                                    </Form.Item>
                                </Col>

                                {/*<Col span={12}>
                                    <Form.Item
                                        label={"Status"}
                                        name={"status"}
                                        required={true}
                                        rules={[{required: true}]}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                        >
                                            {Object.keys(Status).map(status => <Select.Option key={status} value={status}>{Status[status]}</Select.Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>*/}



                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
}

const AddMessage = ({ bookingId, onSuccess, disabled }) => {

    const [value, onChange] = useState("");
    const [submitting, setSubmitting] = useState(false);

    const onSubmit = async () => {
        try {
            setSubmitting(true);

            const response = await api.post(`booking/${bookingId}/message`, {
                message: value,
            });

            if(response.data) {
                onSuccess && onSuccess(response.data);
                onChange("");
            }
        } catch(e) {

        }
        finally {
            setSubmitting(false);
        }
    };

    return (
    <Row gutter={8} justify="center" align="bottom">
        <Col span={20}>
            <Input.TextArea disabled={submitting || disabled} autoSize onChange={(e) => onChange(e.target.value)} value={value}/>
        </Col>
        <Col span={4}>
            <Button block htmlType="button" disabled={!value || disabled} loading={submitting} onClick={onSubmit} type="primary">
                <SendOutlined />
            </Button>
        </Col>
    </Row>);

};

const CommentList = ({ comments }) => (
    <List
        dataSource={comments}
        itemLayout="horizontal"
        renderItem={props =>
            <Comment
                content={props.message}
                datetime={moment(props.createdAt).format("HH:mm") + " Uhr - " + moment(props.createdAt).format("DD.MM.YYYY")}
                author={props.author}
                //className={props.sender === 'employee' ? 'commentRight' : ''}
                avatar={<Avatar>{props.sender === 'employee' ? <ArrowLeftOutlined /> : <ArrowRightOutlined />}</Avatar>}
            />}
    />
);

export default BookingDetail;

